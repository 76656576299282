<template>
  <v-sheet>
    <v-img :src="icon" :width="iconWidth" class="mx-auto mb-5"></v-img>
    <h1 class="text-center" :style="styleTitle">{{ agenceName }}</h1>
    <h2 class="text-center" :style="styleTitle">Code ePhoto</h2>
    <v-alert type="error" v-if="errors.length">
      <ul>
        <li v-for="error in errors" :key="error.id">{{ error }}</li>
      </ul>
    </v-alert>
    <v-text-field label="Email*" v-model="email"></v-text-field>
    <v-text-field label="Confirmez l'email*" v-model="confEmail"></v-text-field>
    <v-text-field label="Prénom*" v-model="firstName"></v-text-field>
    <v-text-field label="Nom*" v-model="lastName"></v-text-field>
    <v-text-field
      label="Identifiant interne"
      v-model="externalId"
    ></v-text-field>

    <v-sheet class="d-flex justify-center mt-5">
      <v-btn
        @click="checkForm"
        :color="buttonValidColor"
        :style="'color:' + buttonTextValidColor"
        >Commencer</v-btn
      >
    </v-sheet>
  </v-sheet>
</template>
<script>
import { requestService } from '@/services/request.service'
import { localStorageService } from '@/services/localStorage.service'

export default {
  name: 'Payment',
  components: {},
  data() {
    return {
      confEmail: '',
      email: '',
      firstName: '',
      lastName: '',
      address: '',
      errors: [],
      agenceName: '',
      externalId: '',

      styleTitle: localStorageService.getStyle(location.hostname, 'titleStyle'),
      buttonValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonValidColor',
      ),
      buttonTextValidColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextValidColor',
      ),
      buttonNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonNeutralColor',
      ),
      buttonTextNeutralColor: localStorageService.getStyle(
        location.hostname,
        'buttonTextNeutralColor',
      ),
      icon: localStorageService.getStyle(location.hostname, 'icon'),
      iconWidth: localStorageService.getStyle(location.hostname, 'iconWidth'),
    }
  },
  methods: {
    checkForm(e) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      this.errors = []
      const isValidEmail = emailRegex.test(this.email)
      if (!this.email) {
        this.errors.push('Saisissez votre email')
        return
      } else if (!isValidEmail) {
        this.errors.push("Cette adresses e-mail n'est pas valide")
        return
      } else if (!this.confEmail || this.email !== this.confEmail) {
        this.errors.push('Confirmez votre email')
        return
      } else if (!this.firstName) {
        this.errors.push('Le prénom est obligatoire')
        return
      } else if (!this.lastName) {
        this.errors.push('Le nom est obligatoire')
        return
      } else {
        this.submit()
      }
      e.preventDefault()
    },
    submit() {
      const partnerAdminUid = this.$route.params.partner_uid
      const partnerId = this.$route.params.agence_id
      const partnerUid = this.$route.params.agence_uid
      requestService
        .post(`/service/auth-qrcode-request-for-student`, {
          userEmail: this.email,
          lastName: this.lastName,
          firstName: this.firstName,
          partnerAdminUid: partnerAdminUid,
          partnerId: partnerId,
          partnerUid: partnerUid,
          externalId: this.externalId,
        })
        .then(response => {
          this.$router.push({
            name: 'Authentication',
            params: { id: response.data.uid },
          })
        })
    },
  },
  mounted() {
    const uuid = localStorageService.getValue('partner_uid')
    if (
      uuid !== undefined &&
      uuid !== null &&
      !localStorageService.getValue('logo')
    ) {
      requestService.get('/partner/uuid/' + uuid).then(response => {
        if (response.data.success) {
          this.agenceName = response.data.name
          if (response.data.logo !== null) this.icon = response.data.logo
          localStorageService.setObject(
            'logo',
            response.data.logo !== null ? response.data.logo : undefined,
          )
          localStorageService.setObject(
            'primaryColor',
            response.data.primaryColor !== null
              ? response.data.primaryColor
              : undefined,
          )
          localStorageService.setObject(
            'secondaryColor',
            response.data.secondaryColor !== null
              ? response.data.secondaryColor
              : undefined,
          )
        }
      })
    }
  },
}
</script>
